import "./App.css";
import PassportForm from "./PassportForm";

function App() {
  return (
    <div className="App">
      <div className="akaiLogo"> </div>

      <PassportForm />
    </div>
  );
}

export default App;
