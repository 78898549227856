import React, { useState } from "react";
import { TextField, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import { styled } from "@mui/system";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";

const appointAPI = axios.create({
  baseURL: `https://freshjollof.com/api/v1/appointments`,
});

const countAPI = axios.create({
  baseURL: `https://freshjollof.com/api/v1/counts`,
});

const FormContainer = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  margin: 0 auto;
`;

const PassportForm = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(
    <div>
      Congratulations!
      <br />
      Your data has been submitted"
    </div>
  );
  const [dateError, setDateError] = useState("");

  const initialState = {
    passportNumber: "",
    destinationCountry: "",
    givenNames: "",
    familyName: "",
    preferredAppointmentDate: null,
    phoneNumber: "",
    hapIME: "",
    email: "",
    intendedResidence: "",
    timeSlot: "",
    isKept: false,
  };
  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePreferredAppointmentDateChange = (date) => {
    const mySlot = Math.floor(Math.random() * 3);
    //console.log(mySlot);

    setFormData((prevFormData) => ({
      ...prevFormData,
      timeSlot:
        mySlot < 1 ? "8am-10am" : mySlot === 2 ? "10am-12noon" : "12noon-2pm",
      preferredAppointmentDate: date,
    }));
  };

  const destinationChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      destinationCountry: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Handle form submission here

    const date = new Date(formData.preferredAppointmentDate);

    setConfirmMessage(
      `Congratulations!${formData.givenNames} ${
        formData.familyName
      }, your visa medicals for ${
        formData.destinationCountry
      } has been scheduled for ${
        formData.timeSlot
      } on ${date.toDateString()} GMT `
    );

    //Check to see if the date selected is after today.
    // if so...then check to see if the day selected is not a Sunday
    // then check to see if the day selected is already one of the days in the date database.
    // if not, book based on whatever time was chosen
    // else if it is already one of the days in teh date database, check the time allocation chosen
    // if allocated time slot exceeds 50
    //

    if (date > new Date()) {
      if (date.getDay() > 0) {
        try {
          countAPI
            .get(`/:${date}`)
            .then((res) => {
              console.log("we are checking if there is any data here");

              console.log(res.data.total);
              if (res.data.total < 10) {
                countAPI.patch(`/:${date}`);
                appointAPI.post("/", formData);
                setShowConfirm(true);
                setFormData(initialState);
              } else {
                setDateError(
                  "There are no more slots available on this day. Please choose another day."
                );
              }
            })
            .catch((error) => {
              //if this date doesn't already exist in our database, we include it, so we can later update it
              countAPI.post("/", { date: `${date}`, total: 1 });
              appointAPI.post("/", formData);
              setShowConfirm(true);
              setFormData(initialState);
            });
        } catch (error) {
          console.log("error fetching data");
        }
      } else {
        setDateError("We do not work on sundays");
      }
    } else {
      setDateError("Cannot select a past date!");
    }
  };

  return (
    <div>
      {showConfirm && (
        <h2 style={{ padding: 50, margin: 50 }}>
          <p>{confirmMessage}</p>
          <Button
            onClick={() => {
              setShowConfirm(false);
              setDateError("");
            }}
            variant="contained"
          >
            Complete!
          </Button>
        </h2>
      )}
      {!showConfirm && (
        <FormContainer onSubmit={handleSubmit}>
          <h4>Desired Appointment Date</h4>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Appointment Date"
              value={formData.preferredAppointmentDate}
              onChange={(newValue) =>
                handlePreferredAppointmentDateChange(newValue)
              }
            />
          </LocalizationProvider>

          {!showConfirm && <p style={{ color: "red" }}>{dateError}</p>}

          <h4>Destination Country</h4>
          <Select
            labelId="visa-dropdown-list"
            id="visa-dropdown"
            value={formData.destinationCountry}
            onChange={(newValue) => destinationChange(newValue)}
          >
            <MenuItem value="United Kingdom">United Kingdom</MenuItem>
            <MenuItem value="United States">United States</MenuItem>
            <MenuItem value="Belgium">Belgium</MenuItem>
            <MenuItem value="New Zealand">New Zealand</MenuItem>
            <MenuItem value="Australia">Australia</MenuItem>
            <MenuItem value="Canada">Canada</MenuItem>
            <MenuItem value="China">China</MenuItem>
            <MenuItem value="South Africa">South Africa</MenuItem>
          </Select>
          {formData.destinationCountry === "Australia" && (
            <TextField
              label="HAP Number (Australia Only)"
              name="hapIME"
              value={formData.hapIME}
              onChange={handleChange}
              required
            />
          )}
          {formData.destinationCountry === "Canada" && (
            <TextField
              label="IME Number (Canada Only)"
              name="hapIME"
              value={formData.hapIME}
              onChange={handleChange}
              required
            />
          )}
          <TextField
            label="Intended Residence"
            name="intendedResidence"
            value={formData.intendedResidence}
            onChange={handleChange}
            required
          />

          <h4>Your Personal Data</h4>
          <TextField
            label="Passport number"
            name="passportNumber"
            value={formData.passportNumber}
            onChange={handleChange}
            required
          />

          <TextField
            label="Given name(s) (as shown in passport)"
            name="givenNames"
            value={formData.givenNames}
            onChange={handleChange}
            required
          />

          <TextField
            label="Family name (as shown in passport)"
            name="familyName"
            value={formData.familyName}
            onChange={handleChange}
            required
          />

          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />

          <TextField
            label="Email Address"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <Button type="submit" variant="contained">
            Submit
          </Button>
        </FormContainer>
      )}
    </div>
  );
};

export default PassportForm;
